.constitution-body{
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 2.5%; 
}


.constitution-title{
    margin-top: 1rem;
}
.event-para{
    margin: 0 1%;
    text-align: justify;
}

hr {
    border-style: dotted;
    border-bottom: none;
    border-width: 5px;
    width: 5%;
    margin: 2rem auto;
    color: #b2b2b2;
    background-color: white;
  
  }

  h1,h2{
    margin-bottom: 1.5rem;
}

.fundamental-para{
    margin: auto 17%;
    text-align: justify;
}
h5{
    margin-top: 0.2rem;
    margin-bottom: 0;
}



.title{
    text-decoration-color: #f18518;
    text-align: center;
    align-items: center;
    font-size:larger;
    margin: 0 1rem;
}
.title-img {
    max-height: 6vh;
    margin: 2vh 0; 
}

.elements {
    display: flex;
    justify-content: center;
    padding: 1rem 5%;
    
}
.flag-color {
    justify-content: center;
    max-width: 420vh;
}
.flag-img {
    max-width: 250px;
}


.chakra-all
{
    display: flex;
}

.chakra-txt{
    margin-top: 2rem;
    padding: 0 5%;
}

.bullets{
display: flex;
text-align: left;
justify-content: center;
}

.sub-bullets{
    margin-bottom: 0;
}

.heading{
    margin: 1.5rem 0;
}

.blogs{
    color: #f18518;
}

.constitution-title{
    margin-top: 3%;
}

.events{
    font-size: medium;
}
.nav-link.active {
  background-color: #ff8439 !important;
}

.link-body-emphasis,
.nav-link {
  color: #ff8439;
}

.link-body-emphasis:hover,
.nav-link:hover {
  color: #f0630c;
}

.nav-link.active:hover {
  background-color: #f0630c !important;
}
.selected:hover {
  background-color: #f0630c ;
  border-radius: 5px;
}

.header{
  /* background-image: linear-gradient(to right top, #ffffff, #ac7f52); */

}
.independence-body{
    text-align: center;
    margin: 0 2.5%; 
}
.independence-title{
margin-top: 1rem;
}

.events{
    display: flex;
    margin: 4%;
}
img{
    max-width: 20rem;
}

p{
    margin-bottom: 0.1rem;
}



.home {
  text-align: center;
  align-items: center;
  font-weight: 500;
    font-size:larger;
    padding-top: 4vh;
}

.logo {
  max-height: 7vh;
  margin: 2vh 0;
}

.logo2{
  max-height: 8vh;
  margin: 2vh 0;
}
hr {
  border-style: dotted;
  border-bottom: none;
  border-width: 5px;
  width: 5%;
  margin: 1.5rem auto;
  color: #b2b2b2;
  background-color: white;

}

#story {
  text-align: justify;
  align-items: center;
  padding: 0% 10%;
  font-weight: 450;
}
h3 {
  text-align: center;
  align-items: center;
  margin-bottom: 2rem;
}


.cert{
    display: flex;
    justify-content: center;

    background-color: #f9f9f9;
    img{
        margin: 2rem auto;
        align-self: center;
        max-width: 45%;
    }
}

@media (max-width: 576px) {
    .cert{
        img{
            max-width: 100%;
        }
    }
}
#footer{
    padding-top: 1%;
    text-align: center;
}

a {
    color: black;
    text-decoration: none;
}
a:hover{
    color: rgb(88, 88, 88);
}

.footer-icon{
    margin: 20px 10px;
}